import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Company } from 'src/app/shared/models/company';
import { User } from 'src/app/shared/models/user';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(
    private http: HttpClient
  ) { }


  getCompany() {
    return this.http.get(environment.apiUrlPrefix + `/company`);
  }

  updateCompany(company: Company) {
      return this.http.post(environment.apiUrlPrefix + `/company`, company);
  }

  getUser() {
    return this.http.get(environment.apiUrlPrefix + `/user`);
  }

  updateUser(user: User) {
      return this.http.post(environment.apiUrlPrefix + `/user`, user);
  }

}
