import { Component, EventEmitter, Output, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { BuilderStateService } from '../../services/builder-state.service';
import { BuilderService } from '../../services/builder.service';
import { BuilderConstantsService } from '../../services/builder-constants.service';

@Component({
  selector: 'app-builder-page-selector',
  templateUrl: './page-selector.component.html',
  styleUrls: ['./page-selector.component.scss']
})
export class PageSelectorComponent {

  @Input() pages: Array<any>;

  constructor(
    private state: BuilderStateService,
    private service: BuilderService,
    private constants: BuilderConstantsService,
  ) {  }

}
