import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { UrlTree, UrlSegmentGroup, PRIMARY_OUTLET, UrlSegment, Router } from '@angular/router';


@Injectable({
    providedIn: 'root'
  })
export class CampaignService {

    constructor(
        private http: HttpClient,
        private router: Router,
    ) {}

    getCampaign(campaignId: string): Observable<any>{
        if(campaignId == '1b014086a5cf92eb3238d0d45c8c61a4') { // Amorana - TEMP SOLUTION DELETE LATER
            return this.http.get(environment.newApiUrlPrefix + '/api/main' + `/campaign/${campaignId}`);
          }
        return this.http.get(environment.apiUrlPrefix + `/campaign/${campaignId}`);
    }

    getCampaignDetails(campaignId: string){
        return this.http.get(environment.apiUrlPrefix + `/campaign/${campaignId}`).toPromise();
    }

    getCampaigns(status: String, companyId: String) {
        return this.http.get(environment.apiUrlPrefix + `/campaign/${companyId}/all/${status}`);
    }

    pauseCampaign(campaignId: string) {
        return this.http.post(environment.apiUrlPrefix + `/campaign/pause/${campaignId}`, null);
    }

    pauseCampaigns() {
        return this.http.post(environment.apiUrlPrefix + `/campaign/pause/all`, null);
    }

    deleteCampaign(campaignId) {
        return this.http.delete(environment.apiUrlPrefix + `/campaign/${campaignId}`);
    }

    reactivateCampaign(campaignId: string) {
        return this.http.post(environment.apiUrlPrefix + `/campaign/reactivate/${campaignId}`, null);
    }

    getCampaignFromUrl() {
        const tree: UrlTree = this.router.parseUrl(this.router.url);
        const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
        const s: UrlSegment[] = g.segments;
        let campaign_id = s[1].path;

        return this.http.get(environment.apiUrlPrefix + `/campaign/${campaign_id}`).toPromise();
    }

}