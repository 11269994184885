import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MemeoryService {

    constructor(private http: HttpClient) { }

    getFastestPlayers(campaignId) {

        return this.http.get(environment.newApiUrlPrefix + `/api/memory/topPlayers/${campaignId}?numberOfRows=10`);
    }
}
