import { Component, Output, Input } from "@angular/core";
import { Subject, Subscription, forkJoin } from 'rxjs';
import { SwipeItGameSettings } from '@models/SwipeItGameSettings';
import { Product } from '@models/Product';
import { UtilService } from 'src/app/core/util.service';
import { BuilderStateService } from '../../../services/builder-state.service';
import { ModalService } from 'src/app/shared2/modal/modal.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductFormComponent } from './product-form/product-form.component';
import { BuilderService } from '../../../services/builder.service';
import { ProductService } from 'src/app/core/services/product.service';

@Component({
    selector: 'app-swipe-it-game-settings',
    templateUrl: 'swipe-it-game-settings.component.html',
    styleUrls: ['swipe-it-game-settings.component.scss']
})
export class SwipeItGameSettingsComponent {

    @Input() data: SwipeItGameSettings = { items: [] }
    backup: SwipeItGameSettings = { items: [] }
    @Output() output = new Subject()

    productCategories;

    selectedItem: Product = new Product()
    STATE = 'init'// edit new delete and stuff

    newQuestionSubscription: Subscription;
    questionModalSubscription: Subscription;

    constructor(
        private util: UtilService,
        private service: BuilderService,
        private productService: ProductService,
        private state: BuilderStateService,
        private modalService: ModalService,
        private ngbModalService: NgbModal,
    ) {

        const campaignId = this.service.campaign.id

        this.productService.getProductCategories()
            .subscribe((val: Array<any>) => {
                this.productCategories = val
            })

        this.productService.getAllByCampaignId(campaignId)
            .subscribe((val: Array<Product>) => {
                this.data = { items: val }
                this.backup = this.util.copy(this.data)
            })

        this.questionModalSubscription = this.state.$productFormOpened
            .subscribe(val => {
                if (val) {
                    // use pipes malaka
                    const x = this.modalService.open('product-form', ProductFormComponent, 'md', this.selectedItem)
                        .subscribe((val: Product) => {
                            if (this.STATE == 'add')
                                this.data.items.push({ ...val, campaignId: campaignId })

                            x.unsubscribe()
                        })

                }
                else
                    this.modalService.close('product-form')
            })
    }

    onAddItemClick() {
        this.STATE = 'add'
        this.selectedItem = new Product()
        this.state.$productFormOpened.next(true)
    }

    onItemDelete(item: Product) {
        this.STATE = 'delete'
        const idx = this.data.items.indexOf(item)
        this.data.items.splice(idx, 1)
    }

    onItemSelect(item: Product) {
        this.STATE = 'update'
        this.selectedItem = this.data.items.find(el => el.id == item.id)
        this.state.$productFormOpened.next(true)
    }

    onSaveClick() {
        const { new: n, updated: u, deleted: d } = this.util.compareArrays(this.backup.items, this.data.items)
        this._save(n, u, d)
        this.output.next(this.data.items)
    }

    onCloseClick() {
        const { new: n, updated: u, deleted: d } = this.util.compareArrays(this.backup.items, this.data.items)

        if ([].concat(u, d, n).length != 0)
            this.modalService.open("unsaved-changes")
                .subscribe(val => val ? this._save(n, u, d) : this.util.noop())

        this.reset()
        this.state.$gameSettingsOpened.next(false)
    }

    reset = () => {
        this.data = this.util.copy(this.data)
        this.STATE = 'init'
        this.selectedItem = new Product()
    }

    ngOnDestroy() {
        this.questionModalSubscription.unsubscribe()
    }

    private _save(n, u, d) {
        console.log(n, u, d)
        const n$ = n.map(el => this.productService.create(el))
        const u$ = u.map(el => this.productService.update(el))
        const d$ = d.map(el => this.productService.delete(el.id))

        const $ = forkJoin(...n$, ...u$, ...d$)
            .subscribe(responses => {

                // add question id to this.gameSettings.questions
                const new_responses = responses.slice(0, n.length)
                let ind = 0
                this.data.items.forEach((el, idx) => {
                    if (el.id == "") {
                        this.data.items[idx] = new_responses[ind]
                        ind++
                    }
                })

                this.backup = this.util.copy(this.data)
                $.unsubscribe()
            })
    }

}