import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: 'app-page-settings-toolbar',
    templateUrl: 'page-settings-toolbar.component.html',
    styleUrls: ['page-settings-toolbar.component.scss']
})
export class PageSettingsToolbarComponent {

    @Input() page: string;
    @Output() onPageSettingsClick: EventEmitter<boolean> = new EventEmitter<boolean>()
    @Output() onGameSettingsClick: EventEmitter<boolean> = new EventEmitter<boolean>()

    constructor() { }

}