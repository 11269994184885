import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Product } from '@models/Product';


@Injectable({
    providedIn: 'root'
})
export class ProductService {

    constructor(
        private http: HttpClient,
    ) { }

    getAllByCampaignId(campaignId: string) {
        return this.http.get(environment.apiUrlPrefix + `/product/details/all/${campaignId}`)
        // .pipe(tap(data => console.log(data)))
    }

    create(product: Product) {
        return this.http.post(environment.apiUrlPrefix + '/product', product)
        // .pipe(tap(data => console.log(data)))
    }

    delete(productId: string) {
        return this.http.delete(environment.apiUrlPrefix + `/product/${productId}`)
        // .pipe(tap(data => console.log(data)))
    }

    update(product: Product) {
        return this.http.put(environment.apiUrlPrefix + `/product`, product)
        // .pipe(tap(data => console.log(data)))
    }

    getProductCategories(){
        return this.http.get(environment.apiUrlPrefix + '/product/categories')
    }


}