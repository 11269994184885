import { Injectable } from "@angular/core";

@Injectable()
export class LocalStorageService {

    getItem(key: string) {
        const x = localStorage.getItem(key)
        if (x == null)
            throw Error(`LocalStorageService >> requested item not in localstorage !!`)

        return JSON.parse(x)
    }

    setItem(key: string, item: Object) {
        localStorage.setItem(key, JSON.stringify(item))
    }

    removeItem(key: string) {
        localStorage.removeItem(key);
    }

    exists(key: string) {
        const x = localStorage.getItem(key)
        return x != null
    }

}