import { User } from 'src/app/shared/models/user';
import { Company } from 'src/app/shared/models/company';

export class Signup {
    user: User;
    company: Company;

    constructor() {
        this.user = new User();
        this.company = new Company();
    }
}
    