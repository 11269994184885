import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import {CustomHttpInterceptor} from './interceptors/http.interceptor';

import { AuthService } from './auth/auth.service';
import { AuthGuard } from './auth/auth.guard';

import { ServicesModule } from './services/services.module';
import { UtilService } from './util.service';
import { LocalStorageService } from './local-storage.service';

@NgModule({
  imports: [
    RouterModule,
    HttpClientModule,
    ServicesModule,
  ],
  providers: [
    UtilService,
    LocalStorageService,
    AuthService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true
    }
  ],
  declarations: [],
  exports: [
    ServicesModule,
    HttpClientModule,
  ]
})
export class CoreModule { }
