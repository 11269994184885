import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Campaign } from 'src/app/shared/models/campaign';
import { WizardService } from '../../services/wizard.service';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {

  @Input() campaign: Campaign;
  @Output() backStep: EventEmitter<any> = new EventEmitter();

  constructor(
    private wizardService: WizardService
  ) { }

  ngOnInit() {
  }

  goBack() {
    this.backStep.emit({
      goBack: true
    });
  }

  saveCampaign() {
    if(typeof this.campaign.logo === 'string') {
      this.wizardService.saveCampaign(this.campaign).subscribe((response: any) => {
        if(response.md5h != null) {
          window.location.href = `/builder/${response.md5h}`; 
        }
      });
    } else {
      this.readFile(this.campaign.logo).subscribe((logoBase64) => {
        this.campaign.logo = logoBase64;
        this.wizardService.saveCampaign(this.campaign).subscribe((response: any) => {
          if(response.md5h != null) {
            window.location.href = `/builder/${response.md5h}`;
          }
        });
      });
    }
  }

  readFile(file: File): Observable<string> {
    const sub = new Subject<string>();
    var reader = new FileReader();
    reader.onload = () => {
       const content: string = reader.result as string;
       sub.next(content);
       sub.complete();
    };
    reader.readAsDataURL(file);
    return sub.asObservable();
 }

}
