import { Component, EventEmitter, Output, Input, OnChanges } from '@angular/core';
import { UtilService } from 'src/app/core/util.service';
import { BuilderStateService } from '../../../services/builder-state.service';
import { Question } from '@models/Question';
import { QuestionGameSettings } from '@models/QuestionGameSettings';
import { Subscription, Subject, forkJoin } from 'rxjs';
import { ModalService } from 'src/app/shared2/modal/modal.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddQuestionFormComponent } from './add-question-form/add-question-form.component';
import { QuestionsService } from 'src/app/core/services/questions.service';
import { ContextService } from 'src/app/core/services/context.service';
import { ActivatedRoute } from '@angular/router';
import { BuilderService } from '../../../services/builder.service';

const DEFAULT_VM = {
    backgroundImage: '',
    buttonImage: '',
    photoFrame: '',
    questions: []
}

@Component({
    selector: 'app-question-game-settings',
    templateUrl: 'question-game-settings.component.html',
    styleUrls: ['question-game-settings.component.scss']
})
export class QuestionGameSettingsComponent implements OnChanges {

    @Input() data: QuestionGameSettings = DEFAULT_VM
    backup: QuestionGameSettings = DEFAULT_VM // for checking for unsaved changes
    @Output() output = new Subject()

    selectedQuestion: Question = new Question()
    STATE = 'init'// edit new delete and stuff

    newQuestionSubscription: Subscription;
    questionModalSubscription: Subscription;

    constructor(
        private service: BuilderService,
        private util: UtilService,
        private state: BuilderStateService,
        private questionService: QuestionsService,
        private modalService: ModalService,
        private ngbModalService: NgbModal,
    ) {
        // NOTE maybe do this if questions will get changed by another user
        const campaignId = this.service.campaign.id

        this.questionService.getAllByCampaignId(campaignId)
            .subscribe((val: Array<Question>) => {
                this.data = { questions: val }
                this.backup = this.util.copy(this.data)
            })

        this.questionModalSubscription = this.state.$addQuestionOpened
            .subscribe(val => {
                if (val) {
                    // use pipes malaka
                    const x = this.modalService.open('add-question', AddQuestionFormComponent, 'md', this.selectedQuestion)
                        .subscribe((val: Question) => {

                            if (this.STATE == 'add')
                                this.data.questions.push({ ...val, campaignId: campaignId })

                            x.unsubscribe()
                        })

                }
                else
                    this.modalService.close('add-question')
            })

    }

    ngOnChanges(changes) {
        console.log(changes)
    }

    onAddQuestionClick() {
        this.STATE = 'add'
        this.selectedQuestion = new Question()
        this.state.$addQuestionOpened.next(true)
    }

    onQuestionDelete(question: Question) {
        this.STATE = 'delete'
        const idx = this.data.questions.indexOf(question)
        this.data.questions.splice(idx, 1)
    }

    onQuestionSelect(question: Question) {
        this.STATE = 'update'
        this.selectedQuestion = question
        this.state.$addQuestionOpened.next(true)
    }

    onSaveClick() {
        const { new: n, updated: u, deleted: d } = this.util.compareArrays(this.backup.questions, this.data.questions)
        this._save(n, u, d)
        this.output.next(this.data.questions)
        // TODO save
        // this.state.$gameSettingsOpened.next(false)
    }

    onCloseClick() {
        const { new: n, updated: u, deleted: d } = this.util.compareArrays(this.backup.questions, this.data.questions)

        if ([].concat(u, d, n).length != 0)
            this.modalService.open("unsaved-changes")
                .subscribe(val => val ? this._save(n, u, d) : this.util.noop())

        this.reset()
        this.state.$gameSettingsOpened.next(false)
    }

    reset = () => {
        this.data = this.util.copy(this.data)
        this.STATE = 'init'
        this.selectedQuestion = new Question()
    }

    ngOnDestroy() {
        this.questionModalSubscription.unsubscribe()
    }

    private _save(n, u, d) {
        console.log(n, u, d)
        const n$ = n.map(el => this.questionService.create(el))
        const u$ = u.map(el => this.questionService.update(el))
        const d$ = d.map(el => this.questionService.delete(el.id))

        const $ = forkJoin(...n$, ...u$, ...d$)
            .subscribe(responses => {

                // add question id to this.gameSettings.questions
                const new_responses = responses.slice(0, n.length)
                let ind = 0
                this.data.questions.forEach((el, idx) => {
                    if (el.id == "") {
                        this.data.questions[idx] = new_responses[ind]
                        ind++
                    }
                })

                this.backup = this.util.copy(this.data)
                $.unsubscribe()
            })
    }


}