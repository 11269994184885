import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class DateService {

  /*
  FROM: mm/dd/yyyy
  TO: yyyy/mm/dd
  NOTE: add zero before single-digit numbers
  */
 transformDataFormat(sourceDate){
    if(sourceDate.includes('-')) {return sourceDate;}
    let date = sourceDate.split('/');
    let year = date[2];
    let month = Number.parseInt(date[0]) < 10 ? '0' + date[0]: date[0];
    let day = Number.parseInt(date[1]) < 10 ? '0' + date[1]: date[1];;
    return year + '-' + month + '-' + day;
  }
}