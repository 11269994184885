export class BackgroundSettings {
    type: string
    opts: ImageBackgroundSettings | GradientBackgroundSettings | ColorBackroundSettings | TemplateDefaultBgSettings

    // constructor(type, ...args) {
    //     this.type = type
    //     this.opts = (type == "Image") ? new ImageBackgroundSettings
    // }
}

export class ImageBackgroundSettings {
    image: String
}

export class GradientBackgroundSettings {
    mode: string
    angle: string
    startColor: string
    endColort: string
}
export class ColorBackroundSettings {
    color: string
}

export class TemplateDefaultBgSettings { }