import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: 'app-item-list',
    templateUrl: 'item-list.component.html',
    styleUrls: ['item-list.component.scss']
})
export class ItemListComponent {

    @Input() items;
    @Input() options // i put categories here for now
    @Output() onItemSelectClick = new EventEmitter()
    @Output() onItemDeleteClick = new EventEmitter()

    onSelectClick(e, item) {
        e.stopPropagation()
        this.onItemSelectClick.emit(item)
    }

    onDeleteClick(e, item) {
        e.stopPropagation()
        this.onItemDeleteClick.emit(item)
    }

}