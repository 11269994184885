import { Component, Input, forwardRef, Output } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { timeStamp } from 'console';
import { ThemeService } from 'ng2-charts';


@Component({
    selector: 'app-text-input',
    templateUrl: "text-input.component.html",
    styleUrls: ["text-input.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextInputComponent),
            multi: true
        }
    ]
})
export class TextInputComponent implements ControlValueAccessor {

    @Input() labelText: string;
    @Input() options: string;
    
    @Input() showClear: boolean = true
    @Input() clearCallback: Function = this._defaultClear

    constructor() { }
    onChange: any = () => { }
    onTouch: any = () => { }
    val = "" // this is the updated value that the class accesses

    set value(val) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
        this.val = val
        this.onChange(val)
        this.onTouch(val)
    }

    private _defaultClear(){
        this.val = ""
        this.onChange("")
    }

    // this method sets the value programmatically
    writeValue(value: any) {
        this.value = value
    }
    // upon UI element value changes, this method gets triggered
    registerOnChange(fn: any) {
        this.onChange = fn
    }
    // upon touching the element, this method gets triggered
    registerOnTouched(fn: any) {
        this.onTouch = fn
    }
}