import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Credentials } from 'src/app/features/login//models/credentials';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  httpHeaders = new HttpHeaders({'Content-Type' : 'application/json'});

  constructor(
    private http: HttpClient
  ) { }

  login(credentials: Credentials) {
    return this.http.post(environment.apiUrlPrefix + `/login`, credentials, {headers: this.httpHeaders});
  }
}
