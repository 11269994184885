import { NgModule } from "@angular/core";

import { QuestionGameSettingsComponent } from './question-game-setings/question-game-settings.component';
import { QuestionListComponent } from './question-game-setings/question-list/question-list.component';
import { AddQuestionFormComponent } from './question-game-setings/add-question-form/add-question-form.component';
import { SharedModule2 } from 'src/app/shared2/shared.module';
import { FormsModule } from '@angular/forms';
import { SwipeItGameSettingsComponent } from './swipe-it-game-settings/swipe-it-game-settings.component';
import { ProductFormComponent } from './swipe-it-game-settings/product-form/product-form.component';
import { ItemListComponent } from './swipe-it-game-settings/item-list/item-list.component';

@NgModule({
    imports:[
        SharedModule2,
    ],
    declarations: [
        // for quiz and guess the pic
        QuestionGameSettingsComponent,
        QuestionListComponent,
        AddQuestionFormComponent,

        // for swipe it
        SwipeItGameSettingsComponent,
        ProductFormComponent,
        ItemListComponent, // make this one reusable

        // for personality test TODO
        // for spin the wheel TODO
    ],
    entryComponents: [
        QuestionGameSettingsComponent,
        AddQuestionFormComponent,

        SwipeItGameSettingsComponent,
        ProductFormComponent,
    ],
    exports: [
        QuestionGameSettingsComponent,
        AddQuestionFormComponent,
        SwipeItGameSettingsComponent,
        ProductFormComponent,
    ]
})
export class GameSettingsModule { }