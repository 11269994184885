import { Component } from '@angular/core';
import { BuilderService } from '../../../services/builder.service';

@Component({
  selector: 'templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent {

  constructor(private builderService: BuilderService) { }

  selectTemplate(index){
    this.builderService.selectTemplate(index);
  }

}
