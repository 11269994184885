import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  template: `<router-outlet>
                <app-loading-spinner></app-loading-spinner>
            </router-outlet>`,
  styles: []
})
export class AppComponent {
  title = 'builderPOC';
}
