import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: 'app-question-list',
    templateUrl: 'question-list.component.html',
    styleUrls: ['question-list.component.scss']
})
export class QuestionListComponent {

    @Input() questions;
    @Output() onQuestionSelectClick = new EventEmitter()
    @Output() onQuestionDeleteClick = new EventEmitter()

    onSelectClick(e, question) {
        e.stopPropagation()
        this.onQuestionSelectClick.emit(question)
    }

    onDeleteClick(e, question) {
        e.stopPropagation()
        this.onQuestionDeleteClick.emit(question)
    }

}