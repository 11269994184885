import { Component, OnInit } from '@angular/core';
import { BuilderService } from '../../../services/builder.service';

@Component({
  selector: 'general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralComponent {

  constructor(private builderService: BuilderService) { }

}
