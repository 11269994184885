import { NgModule } from "@angular/core";
import { TextInputComponent } from './text-input/text-input.component';
import { FileInputComponent } from './file-upload/file-upload.component';
import { SelectInputComponent } from './select-input/select-input.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { CommonModule } from '@angular/common';
import { ColorPickerModule } from 'ngx-color-picker';

@NgModule({
    imports:[
        CommonModule,
        ColorPickerModule,
    ],
    declarations:[
        TextInputComponent,
        FileInputComponent,
        SelectInputComponent,
        ColorPickerComponent,
    ],
    exports:[
        TextInputComponent,
        FileInputComponent,
        SelectInputComponent,
        ColorPickerComponent,
    ]
})
export class ComponentsModule{}