import { Component, OnInit } from '@angular/core';
import { BuilderService } from '../../../services/builder.service';
import { BuilderStateService } from '../../../services/builder-state.service';

@Component({
  selector: 'games',
  templateUrl: './games.component.html',
  styleUrls: ['./games.component.scss']
})
export class GamesComponent implements OnInit {

  constructor(
    private builderService: BuilderService,
    private state: BuilderStateService,
  ) { }

  ngOnInit() {
  }

  selectGame(index) {
    this.builderService.selectGame(index);
    this.state.$selectedGame.next(this.builderService.games[index])
  }

}
