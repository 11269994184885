import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WizardRoutingModule } from './wizard-routing.module';
import { WizardContainerComponent } from './containers/wizard-container/wizard-container.component';
import { IntroComponent } from './components/intro/intro.component';
import { BrandComponent } from './components/brand/brand.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { FormsModule } from '@angular/forms';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { TemplateComponent } from './components/template/template.component';
import { GameComponent } from './components/game/game.component';
import { ThankYouComponent } from './components/thank-you/thank-you.component';

@NgModule({
  declarations: [WizardContainerComponent, IntroComponent, BrandComponent, TemplateComponent, GameComponent, ThankYouComponent],
  imports: [
    CommonModule,
    WizardRoutingModule,
    ColorPickerModule,
    FormsModule,
    NgxDropzoneModule
  ]
})
export class WizardModule { }
