import { Injectable } from '@angular/core'

@Injectable()
export class UtilService {

    // compare arrays of items
    // find updated new and deleted items
    // TODO somehow 
    compareArrays = (oldArray: Array<any>, newArray: Array<any>) => {

        //hack - check for ids in arrays
        if (!this.checkForIds(oldArray) || !this.checkForIds(newArray))
            throw Error('util.compareArraysError - Provide object that have an id field')

        const intersection = []
        const u = []
        const d = []

        oldArray.forEach(x => {

            let isIn = false

            newArray.forEach(y => {
                if (x.id != y.id) return
                isIn = true
                intersection.push(x)

                // TODO this sucks rn make a function in util.service 
                // which compares two nested objects
                let isUpdated = false
                Object.keys(x).forEach(key => {
                    if (!Array.isArray(x[key]) || !(x[key] instanceof Object)) {

                        (x[key] !== y[key]) // if the field changed
                            ? isUpdated = true // mark as updated and keep changes
                            : key == "image" // otherwise set " '' cuz then it won't get updated */except id field/
                                ? y[key] = ""
                                : this.nothing()
                    } else {
                        if (x[key].length != y[key].length) isUpdated = true
                        if (x[key].length != x[key].filter(el => y[key].includes(el)).length) isUpdated=true
                    }
                })
                ////

                if (isUpdated) u.push(y)

            })

            if (!isIn) d.push(x)
        })

        const n = newArray.filter(el => !intersection.map(el2 => el2.id).includes(el.id))
        return { new: n, updated: u, deleted: d }
    }

    private checkForIds = (array: Array<any>) => {
        return array.length == array.map(el => el.id).filter(el => el != undefined).length
    }

    copy = stuff => JSON.parse(JSON.stringify(stuff))

    nothing = () => { }
    noop = () => { }

}