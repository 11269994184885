import { Component, Output, EventEmitter } from "@angular/core";
import { BuilderStateService } from '../../services/builder-state.service';
import { BuilderService } from '../../services/builder.service';
import { BuilderConstantsService } from '../../services/builder-constants.service';

@Component({
    selector: 'app-builder-header',
    templateUrl: './builder-header.component.html',
    styleUrls:[ './builder-header.component.scss' ]
})
export class BuilderHeaderComponent{

    @Output() pageChanged = new EventEmitter()

    constructor(
        private state: BuilderStateService,
        private constants: BuilderConstantsService,
        private builderService: BuilderService,
    ){

    }

}