export class Campaign {
    id?: string;
    name?: string;
    description?: string;
    accentColor?: string;
    logo?: any;
    templateId?: string;
    gameId?: string;
  
    constructor() {
      this.id = null;
      this.name = null;
      this.description = null;
      this.accentColor = null;
      this.logo = null;
      this.templateId = null;
      this.gameId = null;
    }
  }