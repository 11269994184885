import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { BuilderRoutingModule} from './builder-routing.module'
import { BuilderComponent } from './builder.component';
import { PageSelectorComponent } from './components/page-selector/page-selector.component';
import { SidemenuComponent } from './components/sidemenu/sidemenu.component';
import { GamesComponent } from './components/sidemenu/games/games.component';
import { GeneralComponent } from './components/sidemenu/general/general.component';
import { TemplatesComponent } from './components/sidemenu/templates/templates.component';
import { BuilderService } from './services/builder.service';
import { SidemenuItemComponent } from './components/sidemenu-item/sidemenu-item.component';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';

import { BuilderStateService } from './services/builder-state.service';
import { BuilderHeaderComponent } from './components/builder-header/builder-header.component';
import { BuilderConstantsService } from './services/builder-constants.service';
// newstuf
import { SharedModule2 } from 'src/app/shared2/shared.module';
import { AbstractComponent } from 'src/app/shared/components/templates/abstract/abstract.component';
import { Abstract2Component } from 'src/app/shared/components/templates/abstract2/abstract2.component';
import { StartupComponent } from 'src/app/shared/components/templates/startup/startup.component';
import { TelecommunicationComponent } from 'src/app/shared/components/templates/telecommunication/telecommunication.component';
import { HotelComponent } from 'src/app/shared/components/templates/hotel/hotel.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ModalService } from 'src/app/shared2/modal/modal.service';
import { GameSettingsModule } from './components/game-settings/game-settings.module';
import { ConforamaComponent } from 'src/app/shared/components/templates/conforama/conforama.component';
import { PageSettingsModule } from './components/page-settings/page-settings.module';
import { FoodComponent } from 'src/app/shared/components/templates/food/food.component';
import { LawyerComponent } from 'src/app/shared/components/templates/lawyer/lawyer.component';
import { ClothesComponent } from 'src/app/shared/components/templates/clothes/clothes.component';

@NgModule({
  declarations: [
    BuilderHeaderComponent,
    PageSelectorComponent,
    SidemenuItemComponent,
    BuilderComponent,
    SidemenuComponent,
    GamesComponent,
    GeneralComponent,
    TemplatesComponent,
    SidemenuComponent,
    SidemenuItemComponent,
    GamesComponent,
    GeneralComponent,
    TemplatesComponent
  ],
  entryComponents:[
    AbstractComponent,
    Abstract2Component,
    StartupComponent,
    TelecommunicationComponent,
    HotelComponent,
    FoodComponent,
    LawyerComponent,
    ClothesComponent,
    ConforamaComponent
  ],
  imports: [
    SharedModule,
    GameSettingsModule,
    PageSettingsModule,

    SharedModule2, // this is new shared module
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BuilderRoutingModule,
    NgbModule,
    SharedComponentsModule,
  ],
  providers: [
    BuilderService,
    BuilderStateService,
    BuilderConstantsService,

    ModalService,
  ]
})
export class BuilderModule { }
