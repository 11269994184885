import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlankLayoutComponent } from './layouts/blank-layout/blank-layout.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { TextAreaInputComponent } from './elements/text-area-input/text-area-input.component';
import { TextInputComponent } from './elements/text-input/text-input.component';
import { NavbarComponent } from './navbar/navbar/navbar.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { Abstract2Component } from './templates/abstract2/abstract2.component';
import { AbstractComponent } from './templates/abstract/abstract.component';
import { StartupComponent } from './templates/startup/startup.component';
import { TelecommunicationComponent } from './templates/telecommunication/telecommunication.component';
import { HotelComponent } from './templates/hotel/hotel.component';
import { ConforamaComponent } from './templates/conforama/conforama.component';
import { FoodComponent } from './templates/food/food.component';
import { LawyerComponent } from './templates/lawyer/lawyer.component';
import { TemplateService } from './service/template.service';
import { ClothesComponent } from './templates/clothes/clothes.component';


const components = [
  BlankLayoutComponent,
  TextAreaInputComponent,
  TextInputComponent,
  NavbarComponent,
  AbstractComponent,
  Abstract2Component,
  StartupComponent,
  TelecommunicationComponent,
  HotelComponent,
  FoodComponent,
  LawyerComponent,
  ClothesComponent,
  ConforamaComponent,
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    NgbModule
  ],
  declarations: components,
  exports: components,
  providers: [
    TemplateService
  ]
})
export class SharedComponentsModule { }
