import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContextService } from 'src/app/core/services/context.service';
import { Me } from 'src/app/core/models/user';
import { CampaignService } from 'src/app/features/campaign/services/campaign.service';

@Component({
  selector: 'app-campaign-table',
  templateUrl: './campaign-table.component.html',
  styleUrls: ['./campaign-table.component.scss']
})
export class CampaignTableComponent implements OnInit {

  modal: any;
  selectedCampaign: any;
  campaignsStatus: string;
  action: string;
  campaigns: any;
  me: Me;
  currentRoute: string;
  reactivateErrorMessage: String;

  constructor(
    private context: ContextService,
    private router: Router,
    private campaignService: CampaignService
  ) { }

  ngOnInit() {
    this.campaignsStatus = 'Active';
    this.reactivateErrorMessage = '';
    this.currentRoute = this.router.url.replace("/", "");
    this.me = this.context.getMe();
    this.loadCampaigns();
    this.modal = document.getElementById('modal') as HTMLElement;
  }

  loadCampaigns(){
    this.campaignService.getCampaigns(this.campaignsStatus, this.context.getMe().company.id).subscribe((response: any) => {
      this.campaigns = response;
    });
  }

  newCampaign(){
    this.router.navigateByUrl('/wizard');
  }

  allCampaigns(){
    this.router.navigateByUrl('/campaigns');
  }

  confirm() {
    if(this.action == 'pause') {
      this.campaignService.pauseCampaign(this.selectedCampaign.id).subscribe(response => {
        this.removeCampaignFromList(this.selectedCampaign.id);
        this.modal.style.display = 'none';
      });
    }
    if(this.action == 'delete') {
      this.campaignService.deleteCampaign(this.selectedCampaign.id).subscribe(response => {
        this.removeCampaignFromList(this.selectedCampaign.id);
        this.modal.style.display = 'none';
      });
    }
    if(this.action == 'reactivate') {
      this.campaignService.reactivateCampaign(this.selectedCampaign.id).subscribe((response: any) => {
        if(response.status == 204) {
          this.reactivateErrorMessage = response.message;
        }
        if(response.status == 200) {
          this.removeCampaignFromList(this.selectedCampaign.id);
          this.modal.style.display = 'none';
        }
      });
    }
  }

  editCampaign(campaign){
    window.location.href = `/builder/${campaign.id}`; 
  }

  campaignDetails(campaign){
    this.router.navigateByUrl(`/campaign/details/${campaign.id}`);
  }

  openCampaign(campaign){
    if(campaign.id === '65e1a7f5960776b06ecf645c0434e8e0') { // conforama
      window.open( "http://conforama-swipe-it-official.s3-website.eu-central-1.amazonaws.com", '_blank');
      return;
    }
    if(campaign.id === 'ece82becaecbcf84ac6860f1df6527e9') { //interlaken
      window.open( "https://server.brame.ch/interlaken-personality/?lang=en", '_blank');
      return;
    }
    if(campaign.id === '388bc97757c8a8eeb1eaf65c35230bcb') { //interlaken calender
      window.open( "https://server.brame.ch/interlaken-november-kalender", '_blank');
      return;
    }
    if(campaign.id === '1b014086a5cf92eb3238d0d45c8c61a4') { //amorana
      window.open( "https://server.brame.ch/amorana-swipe-it/", '_blank');
      return;
    }
    if(campaign.id === '0fd53a826d29830117b09ef59d5e6c55') { // fcb zurich
      window.open( "https://server.brame.ch/fcb-zurich-swipe-it/", '_blank');
      return;
    }
    if(campaign.id === '4ebcfea9f04efc18c963e002feb67efa') { // verke
      window.open("https://server.brame.ch/verkehrshaus-memory")
    }

    if(campaign.id === '6b177ff6c105ac9e72345534fd64e23d') { // cheezy
      window.open("https://server.brame.ch/cheezy-swipe-it/")
    }
    
    // window.open( `/${campaign.slug}/${campaign.id}`, '_blank');
  }

  openModal($event, action) {
    this.action = action;
    this.selectedCampaign = $event;
    this.modal.style.display = 'block';
  }

  closeModal() {
    this.modal.style.display = 'none';
    this.reactivateErrorMessage = '';
  }

  setCampaignStatus(status){
    this.campaignsStatus = status;
    this.loadCampaigns();
  }

  removeCampaignFromList(id: string){
    for (var i = this.campaigns.length - 1; i >= 0; --i) {
      if (this.campaigns[i].id == id) {
        this.campaigns.splice(i,1);
      }
    }
  }
}
