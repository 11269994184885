import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CampaignsListingComponent } from './components/campaign-listing/campaign-listing.component';

const routes: Routes = [
    { path: '', component: CampaignsListingComponent }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CampaignsListingRoutingModule { }