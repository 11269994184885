import { Component, Output, Input, OnInit, OnChanges } from "@angular/core";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

import { Product } from '@models/Product';
import { BuilderStateService } from '../../../../services/builder-state.service';
import { ProductService } from 'src/app/core/services/product.service';

@Component({
    selector: 'app-product-form',
    templateUrl: 'product-form.component.html',
    styleUrls: ['product-form.component.scss']
})
export class ProductFormComponent implements OnInit, OnChanges {

    @Input() data: Product;
    @Input() categories: Array<any>;
    @Output() output = new Subject()

    productCategories: Array<any> = []
    productCategoryNames: Array<String> = []

    constructor(
        private state: BuilderStateService,
        private productService: ProductService,
        private ngbModalService: NgbModal,
    ) {
        this.productService.getProductCategories()
            .subscribe((val: Array<any>) => {
                this.productCategories = val
                this.productCategoryNames = val.map(el => el.name)
            })

    }

    ngOnInit(){
        console.log("INIT", this.data)
    }

    ngOnChanges(changes){
        console.log("CHANGES", changes)
    }

    categoryChanged(e) {
        console.log(this.data)
    }

    onConfirmClick() {
        this.data.correctPrice = Number(this.data.correctPrice)
        this.data.wrongPrice = Number(this.data.wrongPrice)
        this.output.next(this.data)
        this.state.$productFormOpened.next(false)
    }

    onCancelClick() {
        this.state.$productFormOpened.next(false)
    }

    reset = () => this.data = new Product()

    trackByFn(index: any, item: any) {
        return index;
    }

}