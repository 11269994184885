import { Component, OnInit } from '@angular/core';
import { Company } from 'src/app/shared/models/company';
import { AccountService } from '../../services/account.service';
import { User } from 'src/app/shared/models/user';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  company: Company;
  user: User;

  constructor(
    private accountService: AccountService
  ) { }

  ngOnInit() {
    this.company = new Company();
    this.user = new User();
    this.accountService.getCompany().subscribe(company => {
      this.company = company;
    });
    this.accountService.getUser().subscribe(user => {
      this.user = user;
      this.user.password = "????????";
    });
  }

  saveCompany(){
    this.accountService.updateCompany(this.company).subscribe(company => {
      this.company = company;
    });
  }

  saveUser(){
    if(this.user.password == "????????") {
      this.user.password = null;
    }
    this.accountService.updateUser(this.user).subscribe(user => {
      this.user = user;
      this.user.password = "????????";
    });
  }
}
