import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs';
import { Me } from '@models/user';
import { ContextService } from 'src/app/core/services/context.service';

@Injectable({
  providedIn: 'root'
})
export class WizardService {

  private httpHeaders = new HttpHeaders({'Content-Type' : 'application/json'});

  me: Me;
  
  constructor(
    private http: HttpClient,
    private context: ContextService
  ) { 
    this.me = this.context.getMe();
  }

  saveCampaign(campaign: any) {
    return this.http.post(environment.apiUrlPrefix + '/campaign', campaign, {headers: this.httpHeaders}).pipe(catchError(this.handleError));
  }

  getGames() {
    return this.http.get(environment.apiUrlPrefix + '/games');
  }

  getTemplates() {
    return this.http.get(environment.apiUrlPrefix + `/templates/${this.me.company.id}`);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.log('An error occurred:', error.error.message);
    } else {
      console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
    }
    return throwError('Something bad happened; please try again later.');
  }
}
