import { Component, Input } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { BuilderStateService } from '../../services/builder-state.service';

@Component({
  selector: 'sidemenu-item',
  templateUrl: './sidemenu-item.component.html',
  styleUrls: ['./sidemenu-item.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':leave', [   // :enter is alias to 'void => *'
        style({marginRight: '0px'}),
        animate('1s', style({marginRight: '-500px'}))
      ]),
      transition(':enter', [   // :leave is alias to '* => void'
        style({marginRight: '-500px'}),
        animate('1s', style({marginRight: '0px'}))
      ])
    ])
  ]
})
export class SidemenuItemComponent {

  @Input()
  title: string;

  constructor(
    private builderStateService : BuilderStateService,
    ) { }

  closeItemMenu(){
    this.builderStateService.sideMenuItemOpened = false;
    this.builderStateService.$sideMenuOpened.next(true);
  }

}
