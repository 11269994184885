import { Component, Input, forwardRef, ViewChild, ElementRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


@Component({
    selector: 'app-file-upload',
    templateUrl: "file-upload.component.html",
    styleUrls: ["file-upload.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileInputComponent),
            multi: true
        }
    ]
})
export class FileInputComponent implements ControlValueAccessor {

    @Input() labelText: string;
    @ViewChild('file', { static: false }) file: ElementRef;

    fileReader: FileReader = new FileReader()
    fileLoaded: boolean = false;

    val: any = "" // this is the updated value that the class accesses
    filename = "";

    onChange: any = () => { }
    onTouch: any = () => { }

    constructor() {
        this.fileReader.onloadend = e => {
            this.val = this.fileReader.result
            
            this.filename = this.file.nativeElement.files[0].name
            
            // set base64 string as value
            this.onChange(this.val)

            // to avoid on change to be called 
            this.fileLoaded = true
            setTimeout(_=>this.fileLoaded=false, 1000)
        }
    }

    set value(val) { 
        this.val = val
        this.onChange(val)
        this.onTouch(val)
    }

    get value(){
        return this.val
    }

    // this method sets the value programmatically
    writeValue(value: any) {
        this.val = value
        if(value){
            this.filename = value.split("/").pop()
        }
    }
    // upon UI element value changes, this method gets triggered
    registerOnChange(fn: any) {
        
        const wrapOnChange = e => {
            if (this.fileLoaded) return

            const files = this.file.nativeElement.files

            if (files.length > 0) this.fileReader.readAsDataURL(files[0])

            fn(e)
        }
        this.onChange = wrapOnChange
    }
    // upon touching the element, this method gets triggered
    registerOnTouched(fn: any) {
        this.onTouch = fn
    }
}