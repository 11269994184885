import { Injectable, Injector, ReflectiveInjector } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';


@Injectable()
export class TemplateService {

    dynamicComponentInjector: Injector;
    loadTemplate: boolean = true;

    initPage: Subject<any> = new Subject();
    contentEditable = true;

    constructor(
        private http: HttpClient,
        private parentInjector: Injector
    ) {

    }

    getHtmlContent(campaign) {
        return this.http.get(campaign.html, { responseType: 'text' });
    }

    disableContentEditable() {
        this.contentEditable = false;
    }

    loadPage(allPages, value: any) {

        if (this.loadTemplate) {
            this.dynamicComponentInjector = ReflectiveInjector.resolveAndCreate([], this.parentInjector);
            this.loadTemplate = false;
        } else {

            allPages.map(el => el.key).forEach((el, idx) => {
                const pageElement = document.getElementById(el)
                if (pageElement) pageElement.style.display = 'none'
            })

            const page = document.getElementById(value)
            if (page) page.style.display = 'block';
            document.getElementById(value).style.display = 'block';
        }
    }


}