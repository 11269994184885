import { Component, Output, Input } from "@angular/core";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

import { BuilderStateService } from '../../../../services/builder-state.service';
import { Question } from '@models/Question';

@Component({
    selector: 'app-add-question-form',
    templateUrl: 'add-question-form.component.html',
    styleUrls: ['add-question-form.component.scss']
})
export class AddQuestionFormComponent {

    @Input() data: Question;
    @Output() output = new Subject()

    constructor(
        private state: BuilderStateService,
        private ngbModalService: NgbModal,
    ) { }

    onAddAnswerClick() {
        this.data.answers.push('')
    }

    onConfirmClick() {
        this.output.next(this.data)
        this.state.$addQuestionOpened.next(false)
    }

    onCancelClick() {
        this.state.$addQuestionOpened.next(false)
    }

    onClearAnswerClick(index: number) {
        return () => {
            this.data.answers.splice(index, 1)
        }
    }

    reset = () => this.data = new Question()

    trackByFn(index: any, item: any) {
        return index;
    }

}