import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { TemplateFactory } from './templates.factory';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { BuilderService } from 'src/app/features/builder/services/builder.service';
import { TemplateService } from '../components/service/template.service';
import { LoadingSpinnerService } from '../components/elements/loading-spinner/service/loading-spinner.service';

export class BaseTemplate{

  data: SafeHtml;
  loaded: boolean = false;

  constructor(private sanitizer: DomSanitizer, 
      private builderService: BuilderService, 
      private spinner: LoadingSpinnerService, 
      private templateService: TemplateService){
    this.setHtml();
    this.builderService.templateChanged.subscribe((data) => {
      let template = TemplateFactory.getTemplate(data.name);
      this.updateHtml(template).subscribe(data=>{
        this.templateService.initPage.next();
      });
    });

  }

  async setHtml() {
    let template = TemplateFactory.getTemplate(this.builderService.campaign.template.name);

    if (this.builderService.campaign.html) {
      await this.templateService.getHtmlContent(this.builderService.campaign).subscribe((html) => {
        if(!this.templateService.contentEditable){
          html = html.replace(/\scontenteditable="true"/g, "");
        }

        template.html = html;
        this.updateHtml(template).subscribe(data=>{
          this.templateService.initPage.next();
          this.loaded = true;
          this.spinner.hide();
        });
      })
    } else {
      this.updateHtml(template).subscribe(data=>{
        this.templateService.initPage.next();
        this.loaded = true;
        this.spinner.hide();
      });
    }
  }

  updateHtml(template) {
    this.loaded = false;
    this.spinner.show();
    this.data = this.sanitizer.bypassSecurityTrustHtml(template.html);
    return of(null).pipe(delay(200));
  }

}