import { Component, OnInit } from '@angular/core';

import { ContextService } from 'src/app/core/services/context.service';
import { Me } from 'src/app/core/models/user';
import { Credentials } from 'src/app/features/login//models/credentials';
import { LoginService } from '../../services/login.service';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { LocalStorageService } from 'src/app/core/local-storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  me: Me;
  credentials: Credentials;
  wrongCredetialsMessage: string;

  constructor(
    private context: ContextService,
    private loginService: LoginService,
    private authService: AuthService,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit() {
      this.credentials = new Credentials();
      if(!this.authService.tokenExpired(this.authService.getToken())) {
        window.location.href = '/dashboard';
      }
  }

  login(){
    if (this.credentials.username && this.credentials.password) {
      this.loginService.login(this.credentials).pipe(catchError(this.handleError)).subscribe((response: any) => {
        if(response && response.code == 200) {
          this.context.init().subscribe(me => {
            this.localStorageService.setItem(environment.contextKeyName, me);
            window.location.href = '/dashboard';
          });
        }
        if(response.status == 403) {
          this.wrongCredetialsMessage = 'Wrong credentials! Please try again.';
        }
      });
    }
  }

  private handleError(error: HttpErrorResponse) {
    let response = {message: undefined, status: undefined};
    if (error.status == 403) {
      response.message = 'Wrong credentials! Please try again.';
      response.status = 403;
    }
    return of(response);
  }
}
