import { Injectable } from "@angular/core";

@Injectable()
export class BuilderConstantsService {

  pages = [
    {
      key: 'introduction',
      value: "Introduction"
    },
    {
      key: 'game-view',
      value: "Game View"
    },
    {
      key: 'outcome',
      value: "Outcome"
    },
    {
      key: 'thank-you',
      value: "Thank You"
    },
    {
      key: 'privacy',
      value: "Privacy Policy"
    },
    {
      key: 'terms',
      value: "Terms of Service"
    }
  ];

  // default image backgrounds for templates
  defaultImages = {
    'Telecommunication': 'telecommunication-bg.png',
    'Startup': 'startup-bg.png',
    'Hotel': 'hotel-bg.png',
    'Abstract': 'abstract-bg.png',
    'Abstract 2': 'abstract2-bg.png',
    'Conforama': 'conforama-bg.png',
  }

}