import { Injectable } from "@angular/core";
import { BuilderConstantsService } from "./builder-constants.service"

@Injectable()
export class ElementParsingService {

    constructor(
        private constants: BuilderConstantsService
    ) { }

    setBackgroundSettingsToElement(element_id: string, settings, templateName?) {
        const mainElement: any = document.getElementById(element_id)

        // reset background
        mainElement.style.background = 'unset'
        mainElement.style.backgroundImage = 'unset'
        mainElement.style.backgroundColor = 'unset'
        //

        switch (settings.type) {
            case "Template Default": {
                const templateDefaultImage = this.constants.defaultImages[templateName]
                mainElement.style.backgroundImage = `url(${templateDefaultImage})`
                mainElement.style.backgroundSize = "cover"
                mainElement.style.backgroundPosition = "center"
                mainElement.style.backgroundRepeat = "no-repeat"
                break
            }
            case "Image": {
                mainElement.style.backgroundImage = `url(${settings.opts.image})`
                mainElement.style.backgroundSize = "cover"
                mainElement.style.backgroundPosition = "center"
                mainElement.style.backgroundRepeat = "no-repeat"
                break
            }
            case "Color": {
                mainElement.style.backgroundColor = settings.opts.color
                break
            }
            case "Gradient": {
                const x = settings.opts.mode == 'radial' ? 'circle' : settings.opts.angle
                const xx = `${settings.opts.mode}-gradient(${x}, ${settings.opts.startColor} 0%, 50%, ${settings.opts.endColor} 100%)`
                mainElement.style.background = xx
                break
            }
        }
    }

    findBackgroundSettingsOfElement(element_id: string, templateName?) {

        const mainElement: any = document.getElementById(element_id)

        const bcg = mainElement.style.background
        const bcgColor = mainElement.style.backgroundColor
        const bcgImage = mainElement.style.backgroundImage

        //Template default
        const templateDefaultImage = this.constants.defaultImages[templateName]
        console.log(bcgImage, templateDefaultImage, `url(${templateDefaultImage})`)
        if (bcgImage == `url("${templateDefaultImage}")`) {
            return { type: "Template Default", opts: {} }

            // Color
        } else if (this.isColorSet(bcgColor)) {
            return { type: 'Color', opts: { color: bcgColor } }
        }

        // Image
        else if (bcgImage.slice(0, 4) == 'url(') {
            return { type: "Image", opts: { image: bcgImage.slice(5, bcgImage.length - 2) } }
        }

        // Gradient
        else if (bcg != 'unset' && bcg != null && bcg != "") {
            // linear-gradient(angle , asd 0%, 50%, asd 100%)
            const [_mode, _angle, _startColor, _endColor] = this.extractGradientArguments(bcg)
            return { type: 'Gradient', opts: { mode: _mode, angle: _angle, startColor: _startColor, endColor: _endColor } }
        }

        return { type: 'Template Default', opts:{} }
    }


    private isColorSet = (cssString: string) => (cssString.slice(0, 4) == "rgb(") || (cssString.slice(0, 5) == "rgba(")

    private extractGradientArguments = string => { // accepts string like this -> (arg1, arg21 arg22, arg3 )
        const tokens = string.slice(16, string.length - 2).split(",")

        const x = string.match(/(rgb|rgba)\(.*?\)/g)

        return [string.slice(0, 6), tokens[0], x[0], x[1]]

    }

}