import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContextService } from 'src/app/core/services/context.service';
import { Me } from 'src/app/core/models/user';
import { AuthService } from 'src/app/core/auth/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  me: Me;
  public navbarCollapsed = true;

  constructor(
    private router: Router,
    private context: ContextService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.me = this.context.getMe();
  }

  logout(){
    this.authService.logout();
    this.router.navigateByUrl('/login');
  }
}
