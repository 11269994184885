import { Component, Input, Output } from "@angular/core";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { BuilderStateService } from '../../../services/builder-state.service';
import { BackgroundSettings, ImageBackgroundSettings, TemplateDefaultBgSettings, ColorBackroundSettings, GradientBackgroundSettings } from './models/BackgroundSettings';

@Component({
    selector: 'app-background-settings',
    templateUrl: 'background-settings.component.html',
    styleUrls: ['background-settings.component.scss']
})
export class BackgroundSettingsComponent {

    @Input() data: BackgroundSettings = new BackgroundSettings()
    @Output() output = new Subject()

    types = [
        "Template Default",
        "Image",
        "Color",
        "Gradient",
    ]

    constructor(
        private state: BuilderStateService,
        private modalService: NgbModal,
    ) { }

    onTypeChanged(e) {
        switch (e) {
            case "Template Default": {
                this.data = { type: e, opts: new TemplateDefaultBgSettings() }
                break
            }
            case "Image": {
                this.data = { type: e, opts: new ImageBackgroundSettings() }
                break
            }
            case "Color": {
                this.data = { type: e, opts: new ColorBackroundSettings() }
                break
            }
            case "Gradient": {
                this.data = { type: e, opts: new GradientBackgroundSettings() }
                break
            }
        }

    }

    onCloseClick(e) {
        this.reset()
        this.state.$gameSettingsOpened.next(false)
    }

    onSaveClick(e) {
        this.output.next(this.data)
        this.state.$gameSettingsOpened.next(false)
        this.reset()
    }

    private reset = () => this.data = new BackgroundSettings()
}