import { NgModule } from "@angular/core";
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        NgbModalModule
    ],
    exports: [
        NgbModalModule,
    ]
})
export class ModalModule { }
