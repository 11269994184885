export class Company {
    id?: string;
    name?: string;
    website?: string;
    email?: string;
    phone?: string;
    address?: any;
    city?: string;
    zipCode?: string;
    countryId?: string;
    
    constructor() {
      this.id = "";
      this.name = "";
      this.website = "";
      this.email = "";
      this.phone = "";
      this.address = "";
      this.city = "";
      this.zipCode = "";
      this.countryId = "";
    }
}
