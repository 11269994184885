import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Cookie } from 'ng2-cookies';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LocalStorageService } from '../local-storage.service';


@Injectable()
export class AuthService {
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private localStorageService: LocalStorageService
  ) {}

  get isLoggedIn() {
    const token = this.getToken();
    this.loggedIn.next(token !== null && !this.tokenExpired(token));
    return this.loggedIn.asObservable();
  }

  public tokenExpired(token: string): boolean {
    const helper = new JwtHelperService();
    return helper.isTokenExpired(token);
  }

  public getToken(): string {
    return Cookie.get(environment.cookies.brame_at);
  }

  public getCookie(name: string): string {
    return Cookie.get(name);
  }

  public setToken(token: string): void {
    Cookie.set(environment.cookies.brame_at, token, 14, '/');
  }

  public setCookie(name: string, value: string): void {
    Cookie.set(name, value, 14, '/');
  }

  public logout(): void {
    Cookie.delete(environment.cookies.brame_at, '/');
    this.localStorageService.removeItem(environment.contextKeyName);
  }

}
