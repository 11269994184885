import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BuilderService } from './services/builder.service';
import { BuilderStateService } from './services/builder-state.service';
import { BuilderConstantsService } from './services/builder-constants.service';
import { ModalService } from 'src/app/shared2/modal/modal.service';
import { QuestionGameSettingsComponent } from './components/game-settings/question-game-setings/question-game-settings.component';
import { SwipeItGameSettingsComponent } from './components/game-settings/swipe-it-game-settings/swipe-it-game-settings.component';
import { BackgroundSettingsComponent } from './components/page-settings/background-settings/background-settings.component';
import { ElementParsingService } from './services/element-parsing.service';
import { TemplateService } from 'src/app/shared/components/service/template.service';

@Component({
  selector: 'app-builder',
  templateUrl: './builder.component.html',
  styleUrls: ['./builder.component.scss'],
  providers: [ElementParsingService]
})
export class BuilderComponent {

  @ViewChild('sample', { static: false })
  sample: any;

  // campaignId = ''
  // builderService.campaign: any = {}
  // gameSettings: any | QuestionGameSettings | SwipeItGameSettings = {}
  // backup: any | QuestionGameSettings = {}
  public isLoading: false;
  
  constructor(
    activatedRoute: ActivatedRoute,
    private builderService: BuilderService,
    private state: BuilderStateService,
    private constants: BuilderConstantsService,
    private modalService: ModalService,
    private element: ElementParsingService,
    private templateService: TemplateService
  ) {

    templateService.initPage.subscribe(val => {
      this.switchPage(0)
    })
    // TODO make a util.service function that extracts both path params and query params
    // join params and queryParams observables
    activatedRoute.params.subscribe(params => {
      // console.log(params)
      // this.campaignId = params['id']

      // this.campaignService.getCampaign(this.campaignId)
      //   .subscribe(builderService.campaign => {

      //     // get game settings depending on the game type
      //     this.builderService.campaign = builderService.campaign
      //     const gameType = builderService.campaign.game.name

      //     if (['Quiz', 'Guess The Picture'].includes(gameType)) {

      //       // this.questionsService.getAllByCampaignId(this.campaignId)
      //       //   .subscribe((val: Array<Question>) => {
      //       //     this.gameSettings = { questions: val }
      //       //     this.backup = this.util.copy(this.gameSettings)
      //       //   })

      //     } else if ('Swipe It' == gameType) {

      //       this.productService.getAllByCampaignId(this.campaignId)
      //         .subscribe((val: Array<Product>) => {
      //           this.gameSettings = { items: val }
      //           this.backup = this.util.copy(this.gameSettings)
      //         })

      //     }

      //   })

      templateService.initPage.subscribe(val => {
        this.state.$selectedPageIndex.next(0);
      })

      this.state.$selectedGame.subscribe(val => {
        this.builderService.campaign.game = val
        // if (val.name == 'Quizz' && val.name == 'Guess The Picture') this.gameSettings = new QuestionGameSettings()
        // else if (val.name == 'Swipe it') this.gameSettings = new SwipeItGameSettings()
        // //etc...
        // this.backup = this.util.copy(this.gameSettings)
      })

      this.state.$pageSettingsOpened
        .subscribe(val => {
          if (val) {
            const templateName = this.builderService.campaign.template.name

            const backgroundSettings = this.element.findBackgroundSettingsOfElement('main-body', templateName)

            modalService.open('background-settings', BackgroundSettingsComponent, 'sm', backgroundSettings)
              .subscribe(settings => this.element.setBackgroundSettingsToElement('main-body', settings, templateName))

          }
        })

      this.state.$gameSettingsOpened
        .subscribe(val => {
          console.log("OPEN GAME SETTINGS", this.builderService.campaign)
          if (val) {

            if (this.builderService.campaign.game.name == 'Quiz' || this.builderService.campaign.game.name == 'Guess The Picture') {
              this.modalService.open('question-game-settings', QuestionGameSettingsComponent, 'lg')
                // .subscribe((questions: Array<Question>) => {
                //   // build questions and map campaign id to objects
                //   const newGameSettings = {
                //     questions: questions.map(el => ({ ...el, campaignId: this.campaignId }))
                //   }

                //   // compare and update game settings
                //   this.compareAndSave(this.backup, newGameSettings)
                //   x.unsubscribe()
                //   this.modalService.close('question-game-settings')
                // })
            } else

              if (this.builderService.campaign.game.name == 'Swipe It') {
                const x = this.modalService.open('swipe-it-game-settings', SwipeItGameSettingsComponent, 'lg')
                  // .subscribe((newGameSettings: SwipeItGameSettings) => {
                  //   // attach campaign id
                  //   newGameSettings.items = newGameSettings.items.map(el => ({ ...el, campaignId: this.campaignId }))


                  //   // TODO move to some func
                  //   const oldSettings = this.backup
                  //   const newSettings = newGameSettings

                  //   const { new: n, updated: u, deleted: d } = this.util.compareArrays(oldSettings.items, newSettings.items)

                  //   const n$ = n.map(el => this.productService.create(el))
                  //   const u$ = u.map(el => this.productService.update(el))
                  //   const d$ = d.map(el => this.productService.delete(el.id))

                  //   // api calls
                  //   const $ = forkJoin(...n$, ...u$, ...d$)
                  //     .subscribe(responses => {

                  //       // add id to this.gameSettings.questions
                  //       const new_responses = responses.slice(0, n.length)
                  //       let ind = 0
                  //       this.gameSettings.items.forEach((el, idx) => {
                  //         if (el.id == "") {
                  //           this.gameSettings.items[idx] = new_responses[ind]
                  //           ind++
                  //         }
                  //       })

                  //       this.modalService.close('swipe-it-game-settings')
                  //       this.backup = this.util.copy(this.gameSettings)
                  //       $.unsubscribe()
                  //     })
                  //   x.unsubscribe()
                  //   this.modalService.close('swipe-it-game-settings')
                  // })
              }


          } else this.modalService.close('all')
        })

    }).unsubscribe()
  }

  // NOTE : only for question game settings out
  // has todos
  // compareAndSave(oldSettings: QuestionGameSettings, newSettings: QuestionGameSettings) {

  //   const { new: n, updated: u, deleted: d } = this.util.compareArrays(oldSettings.questions, newSettings.questions)

  //   const n$ = n.map(el => this.questionsService.create(el))
  //   const u$ = u.map(el => this.questionsService.update(el))
  //   const d$ = d.map(el => this.questionsService.delete(el.id))

  //   // api calls
  //   const $ = forkJoin(...n$, ...u$, ...d$)
  //     .subscribe(responses => {

  //       // add question id to this.gameSettings.questions
  //       const new_responses = responses.slice(0, n.length)
  //       let ind = 0
  //       this.gameSettings.questions.forEach((el, idx) => {
  //         if (el.id == "") {
  //           this.gameSettings.questions[idx] = new_responses[ind]
  //           ind++
  //         }
  //       })

  //       this.modalService.close('question-game-settings')
  //       this.backup = this.util.copy(this.gameSettings)
  //       $.unsubscribe()
  //     })

  // }

  publish() {
    // this.templateService.hidePages();
    var content = this.sample.nativeElement.childNodes[1].childNodes[1].innerHTML;
    //TODO: remove generated comments
    content = content.match(/<div.*/s);
    //this.download("index.html", content);

    this.builderService.publishCampaign(content).subscribe(
      (response) => {
        console.log(response);
        window.location.href = `/dashboard`;
      },
      (error) => console.log(error)
    );

  }

  switchPage(x: number) {
    // x is -1 for previous page 1 for next page

    let curr: any = this.state.$selectedPageIndex.value

    const idx = (x > 0) ? curr + 1 : curr - 1

    if (idx < 0 || idx > this.constants.pages.length - 1) return

    this.state.$selectedPageIndex.next(idx)

  }

}
