import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Me } from 'src/app/core/models/user';
import { ContextService } from 'src/app/core/services/context.service';

@Component({
  selector: 'app-campaign-listing',
  templateUrl: './campaign-listing.component.html',
  styleUrls: ['./campaign-listing.component.scss']
})
export class CampaignsListingComponent implements OnInit {

  me: Me;

  constructor(
    private router: Router,
    private context: ContextService,
  ) { }

  ngOnInit() {
    this.me = this.context.getMe();
  }

  newCampaign(){
    this.router.navigateByUrl('/wizard');
  }

  show(companyId){
    return (companyId != 'd2f6a6e1fa2c64cd2a1b4380b4e8d4ab')   // conforama company
        && (companyId != '6c1c3ab2282cfad8f098eb46ca69589a')   // interlaken company
        && (companyId != '757e1eee72c148fdc9b789e584d4cbab')   // amorana company
        && (companyId != 'e0f63a4b49fe8fce37d761da3f4a6be3') && (companyId != '752b82c3bd237140ce0c9b967189fba1') && (companyId != '5c3ca2bd36128aaa65cff812f3f41210'); // fcb zurich company, verke, cheezy
  }
}
