import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BuilderService } from 'src/app/features/builder/services/builder.service';
import { BaseTemplate } from 'src/app/shared/templates/base.template';
import { LoadingSpinnerService } from '../../elements/loading-spinner/service/loading-spinner.service';
import { TemplateService } from '../../service/template.service';


@Component({
  selector: 'app-telecommunication',
  templateUrl: './telecommunication.component.html',
  styleUrls: ['./telecommunication.component.scss']
})
export class TelecommunicationComponent extends BaseTemplate {

  constructor(sanitizer: DomSanitizer, builderService: BuilderService, spinner: LoadingSpinnerService, templateService: TemplateService) {
    super(sanitizer, builderService, spinner, templateService);
  }

}
