import { NgModule } from "@angular/core";
import { QuestionsService } from './questions.service';
import { ContextService } from './context.service';

@NgModule({
    providers:[
        QuestionsService,
        ContextService,
    ]
})
export class ServicesModule { }
