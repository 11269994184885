import { Component, Input, Output } from "@angular/core";


@Component({
    selector: 'app-gradient-form',
    template: `
    <app-select-input 
        [(ngModel)]="data.mode"
        [labelText]="'Mode'"
    [options]="gradientOpts">
    </app-select-input>
    
    <app-select-input [disabled]="data.mode == 'radial'"
        [(ngModel)]="data.angle"
        [labelText]="'Angle'"
        [options]="degreesOpts">
    </app-select-input>
    
    <app-color-picker 
        [(ngModel)]="data.startColor"
        [labelText]="'Start Color'">
    </app-color-picker>
    
    <app-color-picker 
        [(ngModel)]="data.endColor" 
        [labelText]="'End Color'">
    </app-color-picker>
    `,
    styleUrls: []
})
export class GradientFormComponent {
    DEFAULT_VM = {
        mode: 'linear',
        angle: '90deg',
        startColor: '#FFFFFF',
        endColor: '#000000'
    }

    @Input() data = JSON.parse(JSON.stringify(this.DEFAULT_VM))


    gradientOpts = [
        'linear',
        'radial',
    ]

    degreesOpts = [
        '90deg',
        '45deg',
        '-90deg',
        '-45deg',
    ]
}