import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignTableComponent } from './components/campaign-table/campaign-table.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { PaginationModule } from "ngx-bootstrap/pagination";


@NgModule({
  declarations: [CampaignTableComponent],
  imports: [
    CommonModule,
    BsDropdownModule.forRoot(),
    ProgressbarModule.forRoot(),
    PaginationModule.forRoot(),
    TooltipModule.forRoot(),
    NgbModule
  ],
  exports: [CampaignTableComponent]
})
export class CampaignTableModule {}