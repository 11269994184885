import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignsListingRoutingModule } from './campaign-listing-routing.module';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { CampaignsListingComponent } from './components/campaign-listing/campaign-listing.component';
import { CampaignTableModule } from '../campaign-table/campaign-table.module';


@NgModule({
  declarations: [CampaignsListingComponent],
  imports: [
    CommonModule,
    CampaignsListingRoutingModule,
    SharedComponentsModule,
    CampaignTableModule
  ]
})
export class CampaignListingModule { }
