import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from 'rxjs';
import { Question } from '@models/Question';


@Injectable()
export class BuilderStateService { 

    public $sideMenuOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    public sideMenuItemOpened: boolean = false

    public $selectedPageIndex: BehaviorSubject<Number> = new BehaviorSubject<Number>(0) 
    public $selectedGame: Subject<any> = new Subject<any>()

    public $pageSettingsOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    public $gameSettingsOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    public $addQuestionOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    public $productFormOpened:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

}