import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RoutingModule } from './app.routing';
import { BuilderModule } from './features/builder/builder.module';
import { WizardModule } from './features/wizard/wizard.module';
import { OthersModule } from './features/others/others.module';
import { SharedModule } from './shared/shared.module';
import { DashboardModule } from './features/dashboard/dashboard.module';
import { AccountModule } from './features/account/account.module';
import { CoreModule } from './core/core.module';
import { LoginModule } from './features/login/login.module';
import { ContextService } from './core/services/context.service';
import { CampaignListingModule } from './features/campaign/campaign-listing/campaign-listing.module';
import { SignupModule } from './features/signup/signup.module';
import { LoadingSpinnerComponent } from './shared/components/elements/loading-spinner/loading-spinner.component';


@NgModule({
  declarations: [
    AppComponent,
    LoadingSpinnerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RoutingModule,
    CoreModule,
    SharedModule,
    BuilderModule,
    WizardModule,
    DashboardModule,
    AccountModule,
    OthersModule,
    LoginModule,
    SignupModule,
    CampaignListingModule,
  ],
  providers: [ContextService],
  bootstrap: [AppComponent]
})
export class AppModule { }
