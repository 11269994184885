import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../local-storage.service';

@Injectable()
export class ContextService {

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
  ) {}

  init() {
    return this.http.get(environment.apiUrlPrefix + `/me`);
  }

  getMe() {
    return this.localStorage.getItem(environment.contextKeyName);
  }

}
