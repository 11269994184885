import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Campaign } from 'src/app/shared/models/campaign';

@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss']
})
export class BrandComponent implements OnInit {

  @Input() campaign: Campaign;
  @Output() nextStep: EventEmitter<any> = new EventEmitter();
  @Output() backStep: EventEmitter<any> = new EventEmitter();
  files: File[] = [];
  previewUrl: string = null;
  isLogoSelected: boolean;

  constructor() { }

  ngOnInit() {
    this.isLogoSelected = false;
    if (this.campaign.logo != null) {
      this.files[0] = this.campaign.logo;
    }
  }

  onSelect(event) {
    if(this.files.length == 0) {
      this.files.push(...event.addedFiles);
      this.campaign.logo = this.files[0];
      this.isLogoSelected = false;
    }

  }
   
  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  openNextStep() {
    if (this.campaign.logo != null) {
      this.nextStep.emit({
        stepTitle: 'app-template',
        addOnStack: true
      });
    } else {
      this.isLogoSelected = true;
    }

  }

  goBack() {
    this.backStep.emit({
      goBack: true
    });
  }
}
