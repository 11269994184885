import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Campaign } from 'src/app/shared/models/campaign';
import { Router } from '@angular/router';
import { PlatformLocation } from '@angular/common';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IntroComponent implements OnInit {

  @Input() campaign: Campaign;
  @Output() nextStep: EventEmitter<any> = new EventEmitter();
  invalidName: boolean;
  
  constructor(private router: Router, location: PlatformLocation) {

    location.onPopState(() => {
        window.location.href = '/dashboard';
    });
   }

  ngOnInit() {
    this.invalidName = false;
  }

  openNextStep() {
    if (this.campaign.name != null && this.campaign.name.length >= 3) {
      this.invalidName = false;
      this.nextStep.emit({
        stepTitle: 'app-brand',
        addOnStack: true
      });
    } else {
      this.invalidName = true;
    }
  }

  goBack(){
    window.location.href = '/dashboard';
  }
}
