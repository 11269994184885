import { ChartOptions } from 'chart.js';

export const visits = {
    labels: [],
    datasets: [
      {
        label: "Visits",
        data: [],
        borderColor: '#11cdef',
        backgroundColor: 'rgba(148,159,177,0.2)',
        pointBackgroundColor: '#11cdef',
        pointRadius: 4,
        pointHoverRadius: 5,
      },
      {
        label: "Leads",
        data: [],
        borderColor: '#2dce89',
        backgroundColor: 'rgba(77,83,96,0.2)',
        pointBackgroundColor: '#2dce89',
        pointRadius: 4,
        pointHoverRadius: 5,
      }
    ]
}

export const leads = {
    labels: [],
    datasets: [
      {
        label: "Leads",
        data: []
      }
    ]
}

export const devicePieData = {
    labels: ["Desktop", "Mobile", "Tablet"],
    datasets: [
      {
        data: [],
        backgroundColor: ['#11cdef', '#2dce89', '#f5365c'],
      }
    ],
  }

export const conversionPieData = {
    labels: ["Visits", "Leads"],
    datasets: [
      {
        data: [],
        backgroundColor: ['#11cdef', '#2dce89'],
      }
    ]
}

// unused for now
export const pieChartOptions: ChartOptions = {
  cutoutPercentage: 50
};