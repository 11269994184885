

import { Component, Input, forwardRef, HostListener, ElementRef, Output, EventEmitter } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


@Component({
    selector: 'app-select-input',
    templateUrl: "select-input.component.html",
    styleUrls: ["select-input.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectInputComponent),
            multi: true
        }
    ]
})
export class SelectInputComponent implements ControlValueAccessor {

    @Input() options: Array<any>;// TODO enable input options to be Array of objects and Array of primitive values 
    @Input() showField: String; // which field to show  if it is array of objects null for array of primitives
    @Input() valueField: string;
    @Input() labelText: string;
    @Output() change = new EventEmitter()

    @HostListener('document:click', ['$event']) onClick() {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.dropdownOpened = false
        }
    }

    selectedItem = null
    dropdownOpened = false

    constructor(
        private eRef: ElementRef,
    ) { }

    onChange: any = () => { }
    onTouch: any = () => { }
    val = "" // this is the updated value that the class accesses

    set value(val) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
        this.val = val
        setTimeout(() => {
            this.selectedItem = this.options.find(el => {
                return this.valueField ? el[this.valueField] == val : el == val
            })
        }, 300) // because this.options is not available

        this.onChange(val)
        this.onTouch(val)
    }

    toggleDropdownMenu = () => this.dropdownOpened = !this.dropdownOpened

    onItemSelected(item) {
        this.selectedItem = item
        const value = this.valueField ? item[this.valueField] : item
        this.val = value
        this.onChange(value)
        this.change.emit(item)
        this.toggleDropdownMenu()
    }

    // this method sets the value programmatically
    writeValue(value: any) {
        this.value = value
    }
    // upon UI element value changes, this method gets triggered
    registerOnChange(fn: any) {
        this.onChange = fn
    }
    // upon touching the element, this method gets triggered
    registerOnTouched(fn: any) {
        this.onTouch = fn
    }
}