import { Injectable } from "@angular/core";
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { of } from 'rxjs';

@Injectable()
export class ModalService {

   private _templates = []

   constructor(private modalService: NgbModal) { }

   open(modal_name: String, component = null, size = 'lg', input = null) {

      const modalConf = {
         id: modal_name,
         modal: null,
      }

      if (modal_name == "unsaved-changes" && component == null) {
         // TODO
         return of(confirm("You have unsaved changes \n\n Press OK to save or Cancel to dissmis"))
      }

      const modalRef: NgbModalRef = this.modalService.open(component, {
         centered: true, size: size, backdrop: 'static',
         keyboard: false
      })

      // bind to @Input
      if(input)
         modalRef.componentInstance.data = input;

      modalConf.modal = modalRef

      this._templates.push(modalConf)

      return modalRef.componentInstance.output
   }

   close(modal_name: String) {
      if (modal_name === 'all') {
         this._templates.forEach(el => {
            el.modal.close()
         })
         this._templates = []
      }

      const idx = this._templates.findIndex(el => el.id == modal_name)
      const modalConf = this._templates[idx]

      if (modalConf && modalConf.modal) {
         modalConf.modal.close()
         this._templates.splice(idx, 1)
      }
   }

}