import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Question } from '@models/Question';
import { tap } from 'rxjs/operators';

@Injectable()
export class QuestionsService {

    constructor(
        private http: HttpClient,
    ) { }

    getAllByCampaignId(campaignId: string) {
        return this.http.get(environment.apiUrlPrefix + `/question/all/${campaignId}`)
            // .pipe(tap(data => console.log(data)))
    }

    create(question: Question) {
        return this.http.post(environment.apiUrlPrefix + '/question', question)
            // .pipe(tap(data => console.log(data)))
    }

    delete(questionId: string) {
        return this.http.delete(environment.apiUrlPrefix + `/question/${questionId}`)
            // .pipe(tap(data => console.log(data)))
    }

    update(question: Question) {
        return this.http.put(environment.apiUrlPrefix + `/question`, question)
            // .pipe(tap(data => console.log(data)))
    }

}