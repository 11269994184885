import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContextService } from 'src/app/core/services/context.service';
import { Analytics, LeadsPerDay, VisitsPerDays } from 'src/app/shared/models/analytics';
import { Me } from 'src/app/core/models/user';
import { AnalyticsService } from '../../services/analytics.service';
import { Chart } from 'chart.js';
import { forkJoin } from 'rxjs';
import { chartOptions, parseOptions } from "../../../../variables/charts";
import { visits, leads, devicePieData, conversionPieData, pieChartOptions} from "../../variables/analytics"
import { CampaignService } from 'src/app/features/campaign/services/campaign.service';
import { SocialService } from '../../services/social.service';
import { LoadingSpinnerService } from 'src/app/shared/components/elements/loading-spinner/service/loading-spinner.service';
import { AnalyticsFilter } from '../../models/filter';
import { DateService } from 'src/app/core/services/date.service';
import { MemeoryService } from '../../services/memoryService';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {

  isCollapsed: boolean = true;
  isAnimated: boolean = false;

  bsValue = new Date();
  bsRangeValue: Date[];
  maxDate = new Date();

  @Output() analtyicsFilter = new EventEmitter<AnalyticsFilter>();

  languages = [
    { name: "All Languages", short: "all", flag: "/assets/flags/world.png" },
    { name: "Deutsch", short: "de", flag: "/assets/flags/germany.png" },
  ]

  selectedLanguage = this.languages[1]; // default language - deutsch
  me: Me;
  googleAnalytics: Analytics;
  customAnalytics: any;
  page = 1;
  pageSize = 10;
  visitPage = 1;
  visitPageSize = 6;
  singleAwardPage = 1;
  singleAwardPageSize = 5;
  happyDayPage = 1;
  happyDayPageSize = 5;
  currentRoute: string;
  campaignId: any;
  campaign: any;
  /*
    Filter analytics
  */
  viewMode = 'All Time';
  startDate: string;
  endDate: string;

  public visitsChart;
  public deviceChart;
  public conversionChart;
  public visits = visits;
  public leads = leads;
  public devicePieData = devicePieData;
  public conversionPieData = conversionPieData;

 socialAnalytics: any;
 fastestPlayers: any;
 singlePlayerPage = 1;
 singlePlayerPageSize = 5;

  constructor(
    private context: ContextService,
    private analyticsService: AnalyticsService,
    private route: ActivatedRoute,
    private campaignService: CampaignService,
    private socialService: SocialService,
    private spinnerService: LoadingSpinnerService,
    private dateService: DateService,
    private memoryService: MemeoryService
  ) {
    this.maxDate.setDate(this.maxDate.getDate() + 7);
    this.bsRangeValue = [this.bsValue, this.maxDate];
  }

  ngOnInit() {
    this.spinnerService.show();
    this.me = this.context.getMe();
    this.campaignId = this.route.snapshot.params['id'];
    // create dates for data filtering
    var start = new Date();
    var end = new Date();
    start.setDate(start.getDate() - 30);
    end.setDate(end.getDate() + 1);
    this.startDate = start.toLocaleString("en-US").split(",")[0];
    this.endDate = end.toLocaleString("en-US").split(",")[0];    
    // create charts
    parseOptions(Chart, chartOptions());
    this.createCharts();
    // load campaign
    if (this.campaignId) {
      this.loadSocialAnalyticsData();
      this.campaignService.getCampaign(this.campaignId).subscribe(camp => {
        this.campaign = camp;
        this.startDate = this.campaign.analyticsStartDate;
        this.endDate = this.dateService.transformDataFormat(this.endDate);
        if(this.campaign.name == 'Conforama'){
          this.languages.push({ name: "Italiano", short: "it", flag: "/assets/flags/italy.png" });
          this.languages.push({ name: "Français", short: "fr", flag: "/assets/flags/france.png" });
        }
        if(this.campaign.name == 'Interlaken Personality' || this.campaign.name == 'Verkehrshaus Memory'){
          this.languages.push({ name: "English", short: "en", flag: "/assets/flags/england.png" });
          this.languages.push({ name: "Français", short: "fr", flag: "/assets/flags/france.png" });
        }
        if(this.campaign.name == 'Interlaken November Kalender'){
          this.selectedLanguage = this.languages[0]; // default language - all
          this.notifyLanguageAndDateChange();
          this.languages.push({ name: "Français", short: "fr", flag: "/assets/flags/france.png" });
        }
        if(this.campaign.name == 'FCB Zürich') {
          this.selectedLanguage = this.languages[0]; // default language - all
        }
        this.loadAnalyticsData(this.startDate, this.endDate);
      });
      this.loadFastestPlayers();
    } else {
      let startDate = "";
      // delete this leter, or whole project xD
      if(this.me.company.name == 'Conforama'){startDate = '2020-09-09'};
      if(this.me.company.name == 'Interlaken Tourismus'){startDate = '2020-09-17'};
      if(this.me.company.name == 'Amorana'){startDate = '2020-10-12'};
      if(this.me.company.name == 'FCB Zürich'){startDate = '2020-10-26'};
      if(this.me.company.name == 'Verkehrshaus'){startDate = '2020-11-17'};
      if(this.me.company.name == 'Cheezy'){startDate = '2020-12-02'};
      this.loadAnalyticsData(startDate, this.dateService.transformDataFormat(this.endDate));
    }
  }

  loadSocialAnalyticsData(){
    if (this.campaignId){
      this.socialService.getSocialStatistics(this.campaignId, this.startDate, this.endDate, this.selectedLanguage.short).subscribe((response: any) => {
        this.socialAnalytics = response;
      });
  }
  }

  loadFastestPlayers(){
    if (this.campaignId){
      this.memoryService.getFastestPlayers(this.campaignId).subscribe((response: any) => {
        this.fastestPlayers = response;
      });
  }
  }

  loadAnalyticsData(startDate, endDate) {
    if (this.campaignId) {
      forkJoin([
        this.analyticsService.getGoogleCampaignAnalytics(this.campaignId, startDate, endDate, this.selectedLanguage.short), 
        this.analyticsService.getCustomCampaignAnalytics(this.campaignId, startDate, endDate, this.selectedLanguage.short),
     ])
     .subscribe((result: any) => {
      this.setGoogleAndCustomAnalytics(result[0], result[1]);
      this.spinnerService.hide();
     }); 
    } else {
      forkJoin([
        this.analyticsService.getGoogleCompanyAnalytics(this.context.getMe().company.id, startDate, endDate), 
        this.analyticsService.getCustomCompanyAnalytics(this.context.getMe().company.id, startDate, endDate, this.selectedLanguage.short),
     ])
     .subscribe((result: any) => {
         this.setGoogleAndCustomAnalytics(result[0], result[1]);
         this.spinnerService.hide();
     }); 
    }
  }

  setViewMode(viewMode) {
    this.viewMode = viewMode;
  }

  setVisitsPerDays(analytics: Array<VisitsPerDays>) {
    let visits = [];
    let days = [];
    analytics.forEach(element => {
      days.push(element.day);
      visits.push(element.visits);
    });
    this.visits.datasets[0].data = visits;
  }

  setLeadsPerDays(leadsPerDays: LeadsPerDay, visitsPerDays: Array<VisitsPerDays>) {
    let union = [...new Set([...visitsPerDays.map(visits => visits.day), ...leadsPerDays.days])];
    union.sort();
    
    for(let i = 0; i < visitsPerDays.length; i++){
      if(visitsPerDays[i].day != union[i])
        visitsPerDays.splice(i, 0, {day: union[i], visits: 0 });
    }
    
    for(let i = 0; i < visitsPerDays.length; i++){
      if(leadsPerDays.days[i] != union[i]){
        leadsPerDays.days.splice(i, 0, union[i]);
        leadsPerDays.leads.splice(i, 0, 0);
      }
    }
    this.visits.datasets[1].data = leadsPerDays.leads;
    this.visits.labels = leadsPerDays.days;
    this.visitsChart.update();
  }

  setDeviceData() {
    if(this.googleAnalytics){
      this.devicePieData.datasets[0].data = [this.googleAnalytics.devices.desktop, this.googleAnalytics.devices.mobile, this.googleAnalytics.devices.tablet];
      this.deviceChart.update();
    }
  }

  setLanguage(language) {
    this.spinnerService.show();
    this.selectedLanguage = language;
    this.notifyLanguageAndDateChange();
    this.loadAnalyticsData(this.startDate, this.endDate);
    this.loadSocialAnalyticsData();
  }

  setConversionData() {
    this.conversionPieData.datasets[0].data = [this.googleAnalytics.uniqueVisitors.total, this.customAnalytics.totalLeads];
    this.conversionChart.update();
  }

  filterPieChartByDate() {
    this.startDate = this.dateService.transformDataFormat(this.bsRangeValue[0].toLocaleString("en-US").split(",")[0]);
    this.endDate = this.dateService.transformDataFormat(this.bsRangeValue[1].toLocaleString("en-US").split(",")[0]);
    this.notifyLanguageAndDateChange();
    this.spinnerService.show();
    this.loadAnalyticsData(this.startDate, this.endDate);
    this.loadSocialAnalyticsData();
  }

  createCharts() {
    this.visitsChart = new Chart('chart-plays', { type: "line", data: this.visits});
    this.deviceChart = new Chart('device-chart-pie', { type: "pie", data: this.devicePieData, options: pieChartOptions});
    this.conversionChart = new Chart('conversion-chart-pie', { type: "pie", data: this.conversionPieData });
  }

  notifyLanguageAndDateChange(){
    const analyticsFilter = new AnalyticsFilter(this.selectedLanguage.short, this.dateService.transformDataFormat(this.startDate), this.dateService.transformDataFormat(this.endDate));
    this.analtyicsFilter.emit(analyticsFilter);
  }

  setGoogleAndCustomAnalytics(googleAnalyticsResult, customAnalyticsResult){
    // set google analytics
    if(googleAnalyticsResult.status == undefined){
      this.googleAnalytics = googleAnalyticsResult;
      this.setVisitsPerDays(this.googleAnalytics.visitsPerDays);
      this.setDeviceData();

    // set custom analytics
    this.customAnalytics = customAnalyticsResult;
    //remove 6 and 7 correct answers for cheezy campaign
    if(this.campaignId == '6b177ff6c105ac9e72345534fd64e23d'){
      this.customAnalytics.totalCorrectAnswers = this.customAnalytics.totalCorrectAnswers.filter(answer=>answer.correctAnswers < 6);
      let total = this.customAnalytics.totalCorrectAnswers.reduce((sum,element)=>sum + element.totalPlays,0);
      this.customAnalytics.totalCorrectAnswers.forEach(element=>{
        element.percent = element.totalPlays / total * 100
      });
    }

    this.customAnalytics.interlakenHappyDayStats = []
    this.setLeadsPerDays(this.customAnalytics.leadsPerDay, this.googleAnalytics.visitsPerDays);
    this.setConversionData();
    this.updateVisitAndLeadData(this.customAnalytics.leadsPerDay, this.googleAnalytics.visitsPerDays);
    }
  }

  updateVisitAndLeadData(leadsPerDays: LeadsPerDay, visitsPerDays: Array<VisitsPerDays>){
    for(let i = 0; i < visitsPerDays.length; i++){
      if(visitsPerDays[i].visits < leadsPerDays.leads[i]){
        visitsPerDays[i].visits = leadsPerDays.leads[i];
      }
    }
    this.visits.datasets[0].data = visitsPerDays.map(x => x.visits);
    this.visits.datasets[1].data = leadsPerDays.leads;
    this.visitsChart.update();
  }

}