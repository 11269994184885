import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { CampaignTableModule } from '../campaign/campaign-table/campaign-table.module';
import { AnalyticsModule } from '../analytics/analytics.module';


@NgModule({
  declarations: [DashboardComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SharedComponentsModule,
    CampaignTableModule,
    AnalyticsModule
  ]
})
export class DashboardModule { }
