
import { Template } from './base';
import { Startup } from './startup.template';
import { Telecommunication } from './telecommunication.template';
import { AbstractTemplate } from './abstract.template';
import { Abstract2Template } from './abstract2.template';
import { Hotel } from './hotel.template';
import { Conforama } from './conforama.template';
import { Food } from './food.template';
import { Lawyer } from './lawyer.template';
import { Clothes } from './clothes.template';

export class TemplateFactory{

    static getTemplate(templateName): Template{
        if(templateName=="Startup"){
            return new Startup();
        }else if(templateName=="Telecommunication"){
            return new Telecommunication();
        }else if(templateName=="Abstract"){
            return new AbstractTemplate();
        }else if(templateName=="Abstract 2"){
            return new Abstract2Template();
        }else if(templateName=="Hotel"){
            return new Hotel();
        }else if(templateName=="Conforama"){
            return new Conforama();
        }else if(templateName=="Food"){
            return new Food();
        }else if(templateName=="Lawyer"){
            return new Lawyer();
        }else if(templateName=="Clothes"){
            return new Clothes();
        }
    }
}

