import { NgModule } from "@angular/core";
import { BackgroundSettingsComponent } from './background-settings/background-settings.component';
import { PageSettingsToolbarComponent } from './page-settings-toolbar/page-settings-toolbar.component';
import { CommonModule } from '@angular/common';
import { SharedModule2 } from 'src/app/shared2/shared.module';
import { GradientFormComponent } from './background-settings/gradient-options-form.component';


@NgModule({
    imports:[
        SharedModule2,
    ],
    declarations: [
        BackgroundSettingsComponent,
        PageSettingsToolbarComponent,

        //move to shared ??
        GradientFormComponent,
    ],
    entryComponents: [
        BackgroundSettingsComponent,
    ],
    exports: [
        BackgroundSettingsComponent,
        PageSettingsToolbarComponent,
    ]
})
export class PageSettingsModule { }