import { Component, OnInit } from '@angular/core';
import { Signup } from '../../models/signup';
import { SignupService } from '../../services/signup.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  signup = new Signup();

  constructor(
    private signupService: SignupService,
  ) { }

  ngOnInit() {
    this.signup.company.countryId = "1"; // TO-DO: Add country dropdown, add password security
  }

  signUp(){
    this.signupService.signUp(this.signup).subscribe(data => {
      window.location.href = "/login";
    });
  }

}
