export class AnalyticsFilter {
    language?: string;
    startDate?: string;
    endDate?: string;

    constructor(language, startDate, endDate){
        this.language = language;
        this.startDate = startDate;
        this.endDate = endDate;
    }
}
