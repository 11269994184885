import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BlankLayoutComponent } from './shared/components/layouts/blank-layout/blank-layout.component';
import { AuthGuard } from './core/auth/auth.guard';
import { LoginComponent } from './features/login/components/login/login.component';
import { SignupComponent } from './features/signup/components/signup/signup.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: './features/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'account',
        canActivate: [AuthGuard],
        loadChildren: './features/account/account.module#AccountModule'
      },
      {
        path: 'others',
        loadChildren: './features/others/others.module#OthersModule'
      },
      {
        path: 'wizard',
        canActivate: [AuthGuard],
        loadChildren: './features/wizard/wizard.module#WizardModule'
      },
      {
        path: 'builder/:id',
        canActivate: [AuthGuard],
        loadChildren: './features/builder/builder.module#BuilderModule'
      },

      {
        path: ':comapny/:id',
        loadChildren: './features/campaign/campaign.module#CampaignModule'
      },
      {
        path: 'campaigns',
        canActivate: [AuthGuard],
        loadChildren: './features/campaign/campaign-listing/campaign-listing.module#CampaignListingModule'
      },
      {
        path: 'campaign/details/:id',
        canActivate: [AuthGuard],
        loadChildren: './features/campaign/campaign-details/campaign-details.module#CampaignDetailsModule'
      },
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'signup',
    component: SignupComponent
  },
  {
    path: '**',
    redirectTo: 'others/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class RoutingModule { }
