import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocialService {

  constructor(private http: HttpClient) { }

  getSocialStatistics(campaignId, startDate, endDate, language) {
    const strDate = this.convertDate(startDate) + ' 00:00:00';
    const enDate = this.convertDate(endDate) + ' 23:59:59';
    return this.http.get(environment.newApiUrlPrefix + `/api/link/analytics/${campaignId}?startDate=${strDate}&endDate=${enDate}&language=${language}`);
  }

  private convertDate(date) {
    if (date.indexOf('/') == -1)
      return date;
    let input = date.split('/');
    input = input[2] + '-' + input[0] + '-' + input[1];
    return input;
  }
}
