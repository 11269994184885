import { Component, Output, EventEmitter } from '@angular/core';
import { BuilderService } from '../../services/builder.service';
import { Me } from 'src/app/core/models/user';
import { ContextService } from 'src/app/core/services/context.service';
import { BuilderStateService } from '../../services/builder-state.service';

@Component({
  selector: 'builder-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent {

  @Output() publishEvent = new EventEmitter();
  me: Me;
  
  constructor(
    private builderService: BuilderService,
    private builderStateService: BuilderStateService,
    private context: ContextService
    ) { 
    this.me = this.context.getMe();
  }

  closeSideMenu(){
    this.builderStateService.$sideMenuOpened.next(false);
  }

  openSideItemBlank(){
    this.builderService.showGames = false;
    this.builderService.showTemplates = false;
    this.builderService.showGeneral = false;

    this.builderStateService.sideMenuItemOpened = true;
  }

  openGeneral(){
    this.openSideItemBlank();
    this.builderService.showGeneral = true;
  }

  openGames(){
    this.openSideItemBlank();
    this.builderService.showGames = true;
  }

  openTemplates(){
    this.openSideItemBlank();
    this.builderService.showTemplates = true;
  }

  publish(){
    this.publishEvent.emit();
  }
  
  goToPage(page){
    window.location.href = page;
  }

}
