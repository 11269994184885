import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {


  constructor(private http: HttpClient) { }

    getGoogleCompanyAnalytics(companyId, startDate, endDate) {
      if(companyId == '757e1eee72c148fdc9b789e584d4cbab' || companyId == 'e0f63a4b49fe8fce37d761da3f4a6be3' || companyId == '752b82c3bd237140ce0c9b967189fba1' || companyId == '5c3ca2bd36128aaa65cff812f3f41210') { // Amorana / FCB Zurich / Werkeh / Cheezy - TEMP SOLUTION DELETE LATER
        return this.http.get(environment.newApiUrlPrefix + '/api' + `/google-analytics/company/${companyId}?startDate=${startDate}&endDate=${endDate}`);
      }
      return this.http.get(environment.apiUrlPrefix + `/analytics/google/company/${companyId}?startDate=${startDate}&endDate=${endDate}`);
    }

    getGoogleCampaignAnalytics(campaignId, startDate, endDate, language) {
      if(campaignId == '1b014086a5cf92eb3238d0d45c8c61a4' || campaignId == '0fd53a826d29830117b09ef59d5e6c55' || campaignId == '4ebcfea9f04efc18c963e002feb67efa' || campaignId == '6b177ff6c105ac9e72345534fd64e23d') { // Amorana / FCB Zurich / Werkeh / Cheezy- TEMP SOLUTION DELETE LATER
        return this.http.get(environment.newApiUrlPrefix + `/api/google-analytics/campaign/${campaignId}/${language}?startDate=${startDate}&endDate=${endDate}`);
      }
      return this.http.get(environment.apiUrlPrefix + `/analytics/google/campaign/${campaignId}/${language}?startDate=${startDate}&endDate=${endDate}`);
    }

    getCustomCompanyAnalytics(companyId, startDate, endDate, language) {
      if(companyId == '757e1eee72c148fdc9b789e584d4cbab' || companyId == 'e0f63a4b49fe8fce37d761da3f4a6be3' || companyId == '752b82c3bd237140ce0c9b967189fba1' || companyId == '5c3ca2bd36128aaa65cff812f3f41210') { // Amorana / FCB Zurich / Werkeh / Cheezy - TEMP SOLUTION DELETE LATER
        return this.http.get(environment.newApiUrlPrefix + '/api/main' + `/analytics/custom/company/${companyId}/${language}?startDate=${startDate}&endDate=${endDate}`);
      }
      return this.http.get(environment.apiUrlPrefix + `/analytics/custom/company/${companyId}/${language}?startDate=${startDate}&endDate=${endDate}`);
    }

    getCustomCampaignAnalytics(campaignId, startDate, endDate, language) {
      if(campaignId == '1b014086a5cf92eb3238d0d45c8c61a4' || campaignId == '0fd53a826d29830117b09ef59d5e6c55' || campaignId == '4ebcfea9f04efc18c963e002feb67efa' || campaignId == '6b177ff6c105ac9e72345534fd64e23d') { // Amorana / FCB Zurich / Werkeh / Cheezy - TEMP SOLUTION DELETE LATER
        return this.http.get(environment.newApiUrlPrefix + '/api/main' + `/analytics/custom/campaign/${campaignId}/${language}?startDate=${startDate}&endDate=${endDate}`);
      }
      return this.http.get(environment.apiUrlPrefix + `/analytics/custom/campaign/${campaignId}/${language}?startDate=${startDate}&endDate=${endDate}`);
    }
}
