import { NgModule } from "@angular/core";
import { ComponentsModule } from './components/components.module';
import { ModalModule } from './modal/modal.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,

        ComponentsModule,
        ModalModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ColorPickerModule,

        ComponentsModule,
        ModalModule,
    ]
})
export class SharedModule2 { }