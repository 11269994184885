import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { TemplateFactory } from 'src/app/shared/templates/templates.factory';
import { BuilderStateService } from './builder-state.service';
import { BuilderConstantsService } from './builder-constants.service';
import { Me } from 'src/app/core/models/user';
import { ContextService } from 'src/app/core/services/context.service';
import { TemplateService } from 'src/app/shared/components/service/template.service';
import { CampaignService } from 'src/app/features/campaign/services/campaign.service';

@Injectable()
export class BuilderService {

    campaign: any = {};
    templates: any;
    games: any;

    me: Me;

    showGeneral = false;
    showGames = false;
    showTemplates = false;

    items = [];
    templateChanged: Subject<any> = new Subject();

    page: string;
    pages: any;

    constructor(
        private http: HttpClient,
        private state: BuilderStateService,
        private constants: BuilderConstantsService,
        private context: ContextService,
        private templateService: TemplateService,
        private campaignService: CampaignService
    ) {

        this.me = this.context.getMe();
        this.pages = constants.pages // set usual pages
        this.state.$selectedPageIndex.subscribe((idx: any) => {
            this.templateService.loadPage(this.pages, this.constants.pages[idx].key)
        })

        // seat personality mocked
        this.http.get(environment.apiUrlPrefix + `/templates/${this.me.company.id}`)
            .subscribe((data: Array<any>) =>
                // this.templates = [{ id: "retardirani id", thumbnail: "", name: 'Seat Personality' }, ...data])
                this.templates = data)


        this.http.get(environment.apiUrlPrefix + `/games`)
            .subscribe((data: Array<any>) =>
                // this.games = [{ id: "asdasdasdasd", name: "Seat Personality", thumbnail: "" }, ...data])
                this.games = data)
        //

        this.loadCampaign();
    }

    async loadCampaign() {
        this.campaign = await this.campaignService.getCampaignFromUrl();
        this.items = [TemplateFactory.getTemplate(this.campaign.template.name)];
    }

    selectGame(i) {
        this.campaign.game = this.games[i];
    }

    selectTemplate(i) {
        this.campaign.template = this.templates[i];
        this.campaign.html = null;
        this.items = [TemplateFactory.getTemplate(this.campaign.template.name)];

        // if template has defined pages 
        this.pages = this.items[0].pages || this.constants.pages

        this.templateChanged.next(this.campaign.template);
    }

    publishCampaign(content) {
        const formData = new FormData();

        formData.append('html', new File(content, 'index.html'));
        formData.append('campaignId', this.campaign.id);
        formData.append('name', this.campaign.name);
        formData.append('description', this.campaign.description);
        formData.append('accentColor', this.campaign.accentColor);
        formData.append('logo', "");
        formData.append('templateId', this.campaign.template.id);
        formData.append('gameId', this.campaign.game.id);

        return this.http.post(environment.apiUrlPrefix + `/campaign/publish/`, formData);
    }
}
