import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Campaign } from 'src/app/shared/models/campaign';
import { WizardService } from '../../services/wizard.service';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss']
})
export class GameComponent implements OnInit {

  @Input() campaign: Campaign;
  @Output() nextStep: EventEmitter<any> = new EventEmitter();
  @Output() backStep: EventEmitter<any> = new EventEmitter();
  isGameSelected: boolean;
  games: any;

  constructor(
    private wizardService: WizardService
  ) { }

  ngOnInit() {
    this.isGameSelected = false;
    this.wizardService.getGames().subscribe(response => {
      this.games = response;
    });
  }

  goBack() {
    this.backStep.emit({
      goBack: true
    });
  }

  onClick(item) {
    this.campaign.gameId = item.id;
  }

  openNextStep() {
    if (this.campaign.gameId != null) {
      this.nextStep.emit({
        stepTitle: 'app-thank-you',
        addOnStack: true
      });
    } else {
      this.isGameSelected = true;
    }
  }
}
