import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Campaign } from 'src/app/shared/models/campaign';

@Component({
  selector: 'app-wizard-container',
  templateUrl: './wizard-container.component.html',
  styleUrls: ['./wizard-container.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WizardContainerComponent implements OnInit {

  campaign: Campaign;
  activeStep: string;
  stepStack: string[] = [];

  constructor() { }

  ngOnInit() {
    this.activeStep = 'app-intro';
    this.campaign = new Campaign();
    this.campaign.accentColor = '#FFDE00'
  }

  openStep($event) {
    if ($event.addOnStack) {
      this.stepStack.push(this.activeStep);
    }
    this.activeStep = $event.stepTitle;
  }

  isActiveStep(title: string) {
    return this.activeStep === title;
  }

  goStepBack() {
    if (this.stepStack.length > 0) {
      this.activeStep = this.stepStack.pop();
    }
  }
}
