import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Campaign } from 'src/app/shared/models/campaign';
import { WizardService } from '../../services/wizard.service';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class TemplateComponent implements OnInit {

  @Input() campaign: Campaign;
  @Output() nextStep: EventEmitter<any> = new EventEmitter();
  @Output() backStep: EventEmitter<any> = new EventEmitter();
  isTemplateSelected: boolean;
  templates: any;

  constructor(
    private wizardService: WizardService
  ) { }

  ngOnInit() {
    this.isTemplateSelected = false;
    this.wizardService.getTemplates().subscribe(response => {
      this.templates = response;
    });
  }

  goBack() {
    this.backStep.emit({
      goBack: true
    });
  }

  onClick(item) {
    this.campaign.templateId = item.id;
  }

  openNextStep() {
    if (this.campaign.templateId != null) {
      this.isTemplateSelected = true;
      this.nextStep.emit({
        stepTitle: 'app-game',
        addOnStack: true
      });
    } else {
      this.isTemplateSelected = true;
    }
  }

}
