
import { throwError as observableThrowError,  Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';


import { AuthService } from '../auth/auth.service';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

  constructor(
    public auth: AuthService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.startsWith('http') && request.url.split('.com/')[0] != 'https://brame-client.s3.eu-central-1.amazonaws') {
      request = request.clone({
        url: request.url,
        setHeaders: {
          Authorization: `Bearer ${this.auth.getToken()}`
        }
      });
    }
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const authorizationHeader = event.headers.get('Authorization');
          if (authorizationHeader) {
            const token = authorizationHeader.replace('Bearer ', '');
            this.auth.setCookie(environment.cookies.brame_at, token);
          } 
        }
        return event;
      }),
      catchError((err: any, caught) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.router.navigate(['/login']);
          }
          return observableThrowError(err);
        }
      })
    );
  }
}
